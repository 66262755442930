import { useCallback } from "react";
import { useReducer } from "react";
import { SubmissionMetaData } from "typings/submission";
import { api } from "utils/api/api";
import { toast } from "react-toastify";
import {
  createAction,
  createAsyncActions,
  createContainer,
  createReducer,
} from "utils/context";

import { AddOffenseType } from "typings/claim";

export type SubmissionContextType = {
  submissionMetaData?: SubmissionMetaData;
  submissionMetaDataLoading: boolean;
  addConvictionOffensesValues: AddOffenseType[];
};

const initialState: SubmissionContextType = {
  submissionMetaDataLoading: true,
  addConvictionOffensesValues: [],
};

const actions = {
  setSubmissionMetaData: createAsyncActions("SET_SUBMISSION_META_DATA"),
  setSubmissionClaim: createAsyncActions("SET_SUBMISSION_CLAIM"),
  setAddConvictionOffensesValues: createAction(
    "SET_ADD_CHARGED_OFFENSES_VALUES"
  ),
};

const submissionReducer = createReducer<SubmissionContextType>({
  [`${actions.setSubmissionMetaData.loading}`]: (state) => ({
    ...state,
    submissionMetaDataLoading: true,
  }),
  [`${actions.setSubmissionMetaData.success}`]: (state, { payload }) => ({
    ...state,
    submissionMetaData: payload,
    submissionMetaDataLoading: false,
  }),
  [`${actions.setSubmissionMetaData.failure}`]: (state) => ({
    ...state,
    submissionMetaDataLoading: false,
  }),
  [actions.setAddConvictionOffensesValues.toString()]: (
    state,
    { payload }
  ) => ({
    ...state,
    addConvictionOffensesValues: payload,
  }),
});

export const {
  useContext: useSubmission,
  Provider: SubmissionProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(submissionReducer, initialState);

  const getSubmissionMetaData = useCallback(async (id) => {
    dispatch(actions.setSubmissionMetaData.loading());
    try {
      const { data } = await api(`/attorney-claims/${id}/submission/metadata`, {
        method: "get",
      });
      dispatch(actions.setSubmissionMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setSubmissionMetaData.failure());
    }
  }, []);

  const setSubmissionClaim = useCallback(
    async (formData, id, onSuccess: () => void) => {
      dispatch(actions.setSubmissionMetaData.loading());
      try {
        const { data } = await api(`/attorney-claims/${id}/submission`, {
          method: "post",
          data: { ...formData },
        });
        dispatch(actions.setSubmissionClaim.success(data.data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        dispatch(actions.setSubmissionClaim.failure());
      }
    },
    []
  );

  // ---API's for Add, Edit and Remove Offense Details - Edit Section

  const setAddConvictionOffensesValues = useCallback(
    async (formData?: AddOffenseType) => {
      dispatch(
        actions.setAddConvictionOffensesValues([
          ...state.addConvictionOffensesValues,
          formData,
        ])
      );
    },
    [state]
  );

  const setRemoveConvictionOffensesValues = useCallback(
    async (offenseIndex?: number) => {
      const newOffenseArray = [...state?.addConvictionOffensesValues];
      newOffenseArray.splice(Number(offenseIndex), 1);
      dispatch(actions.setAddConvictionOffensesValues(newOffenseArray));
    },
    [state]
  );

  const setEditConvictionOffensesValues = useCallback(
    async (formData: AddOffenseType, index: number) => {
      const editArray = [...state?.addConvictionOffensesValues];
      editArray[index] = formData;
      dispatch(actions.setAddConvictionOffensesValues(editArray));
    },
    [state]
  );

  const resetConvictionOffensesValues = useCallback(async () => {
    dispatch(actions.setAddConvictionOffensesValues([]));
  }, []);

  return {
    state,
    actions: {
      getSubmissionMetaData,
      setSubmissionClaim,
      setAddConvictionOffensesValues,
      setRemoveConvictionOffensesValues,
      setEditConvictionOffensesValues,
      resetConvictionOffensesValues,
    },
  };
});
