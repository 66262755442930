import React, { FC } from "react";
import {
  Box as MaterialBox,
  BoxProps as MaterialBoxProps,
} from "@material-ui/core";
import {
  makeStyles,
  Theme as AugmentedTheme,
  createStyles,
} from "@material-ui/core/styles";
import { get } from "lodash";

export type FontWeightType =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "normal"
  | "bold"
  | "bolder"
  | "lighter";

export const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    root: {
      color: (props): string =>
        props.color
          ? /rgb|#/.test(props.color)
            ? props.color
            : get<Record<string, string>, string>(
                theme.palette as any,
                props.color
              ) || "initial"
          : "initial",
      backgroundColor: (props): string =>
        props.bgcolor
          ? /rgb|#/.test(props.bgcolor)
            ? props.bgcolor
            : get<Record<string, string>, string>(
                theme.palette as any,
                props.bgcolor
              ) || "initial"
          : "initial",
      borderColor: (props): string =>
        props.borderColor
          ? /rgb|#/.test(props.borderColor)
            ? props.borderColor
            : get<Record<string, string>, string>(
                theme.palette as any,
                props.borderColor
              ) || "initial"
          : "initial",
      cursor: (props: BoxProps) => props.cursor,
      fontSize: (props: BoxProps) =>
        props?.fontSize
          ? props.fontSize
          : props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].fontSize
          : "1.4rem",
      lineHeight: (props: BoxProps) =>
        props?.lineHeight
          ? props.lineHeight
          : props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].lineHeight
          : "1.7rem",
      fontWeight: (props: BoxProps) =>
        props?.fontWeight
          ? props.fontWeight
          : props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].fontWeight
          : "normal",
      fontFamily: (props: BoxProps) =>
        props?.fontFamily
          ? props.fontFamily
          : props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].fontFamily
          : "Roboto",
      letterSpacing: (props: BoxProps) =>
        props?.letterSpacing
          ? props.letterSpacing
          : props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].letterSpacing
          : "0rem",
      opacity: (props: BoxProps) =>
        props.opacity
          ? props.opacity
          : props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].opacity
          : "0rem",
      textTransform: (props) => props.texttransform,
    },
  })
);

export interface BoxProps extends MaterialBoxProps {
  color?: string;
  cursor?: string;
  borderColor?: string;
  bgcolor?: string;
  fontstylevariant?: string;
  opacity?: number | string;
  texttransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "initial"
    | "inherit";
  ref?: any;
}

export const Box: FC<BoxProps> = (props) => {
  const classes = useStyles(props);
  return (
    <MaterialBox className={classes.root} {...props}>
      {props.children}
    </MaterialBox>
  );
};
