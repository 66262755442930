import { hydrate } from "utils/persist";
import { AUTH_TOKEN_COOKIE } from "utils/api/getTokens";
import api from "./api/api";
import { removeCookie } from "./cookie";

export const AUTH_KEYS = {
  USER_DATA: "userData",
  AUTH_TOKEN_COOKIE,
};

export const handleLogout = async () => {
  const token = hydrate(AUTH_KEYS.AUTH_TOKEN_COOKIE) as string | undefined;
  if (token) {
    const data = await api("clients/logout", { method: "POST" });
    if (data) {
      localStorage.removeItem(AUTH_KEYS.USER_DATA);
      Object.values(AUTH_KEYS).forEach((value) => {
        removeCookie(value);
      });
    }
  }
  localStorage.removeItem(AUTH_KEYS.USER_DATA);
  Object.values(AUTH_KEYS).forEach((value) => {
    removeCookie(value);
  });
};
