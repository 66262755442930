import React, { FC } from "react";
import {
  Button as MaterialButton,
  ButtonProps as MaterialButtonProps,
} from "@material-ui/core";
import {
  makeStyles,
  Theme as AugmentedTheme,
  createStyles,
} from "@material-ui/core/styles";
import { FontWeightType } from "atoms/Box";
import { LocaleString } from "locales";
import { useIntl } from "react-intl";
import { Flex, Loader } from "atoms";

const useStyles = makeStyles((theme: AugmentedTheme) =>
  createStyles({
    root: {
      cursor: (props: ButtonProps) => props.cursor,
      fontSize: (props: ButtonProps) =>
        props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].fontSize
          : "1.4rem",
      lineHeight: (props: ButtonProps) =>
        props.fontstylevariant
          ? theme.fontSets[props.fontstylevariant].lineHeight
          : "1.4rem",
      fontWeight: (props: ButtonProps) =>
        props.fontstylevariant
          ? (theme.fontSets[props.fontstylevariant]
              .fontWeight as FontWeightType)
          : "normal",
      textTransform: (props: ButtonProps) => props.texttransform,
      borderRadius: (props: ButtonProps) => props.borderradius,
      padding: (props: ButtonProps) => props.padding,
    },
  })
);

export interface ButtonProps extends MaterialButtonProps {
  cursor?: string;
  fontstylevariant?: string;
  id?: LocaleString;
  texttransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "initial"
    | "inherit";
  loading?: Boolean;
  component?: string;
  borderradius?: string;
  padding?: string;
  disabled?: boolean;
}

export const Button: FC<ButtonProps> = ({
  id,
  children,
  loading,
  disabled,
  ...props
}) => {
  const intl = useIntl();
  const classes = useStyles(props);
  if (id) {
    return (
      <MaterialButton
        disabled={disabled || loading ? true : false}
        className={classes.root}
        {...props}
      >
        {loading && (
          <Flex
            left={0}
            right={0}
            justifyContent="center"
            alignItems="center"
            fontSize={2}
            height="2rem"
            width="2rem"
            mx="auto"
          >
            <Loader loading />
          </Flex>
        )}
        {!loading && intl.formatMessage({ id: id })}
      </MaterialButton>
    );
  }
  return (
    <MaterialButton className={classes.root} {...props}>
      {children}
    </MaterialButton>
  );
};
