import { useCallback, useReducer } from "react";
import { api } from "utils/api/api";
import { toast } from "react-toastify";
import {
  createContainer,
  createReducer,
  createAsyncActions,
  createAction,
} from "utils/context";
import { CaseData, CaseStatusType } from "typings/case";
import { CaseType } from "typings/client";
import { FilterType } from "typings/claim";

export type MyCasesContextType = {
  cases: CaseData[];
  loading: boolean;
  total: number;
  caseDetail?: CaseData;
  caseDetailLoading: boolean;
  caseMetadata?: any;
  caseMetaDataLoading: boolean;
  caseTypeFilter: FilterType[];
  caseStatusFilter: FilterType[];
};

const initialState: MyCasesContextType = {
  cases: [],
  loading: true,
  total: 0,
  caseDetailLoading: true,
  caseMetaDataLoading: true,
  caseTypeFilter: [],
  caseStatusFilter: [],
};

const actions = {
  setCases: createAsyncActions("SET_CASES"),
  setCaseMetaData: createAsyncActions("SET_CASE_META_DATA"),
  setCaseDetail: createAsyncActions("SET_CASE_DETAIL"),
  setCaseTypeFilter: createAction("SET_CASE_TYPE_FILTER"),
  setCaseStatusFilter: createAction("SET_CASE_STATUS_FILTER"),
};

const casesReducer = createReducer<MyCasesContextType>({
  [`${actions.setCases.loading}`]: (state) => ({
    ...state,
    loading: true,
  }),
  [`${actions.setCases.success}`]: (state, { payload }) => ({
    ...state,
    cases: payload.data,
    total: payload.total,
    loading: false,
  }),
  [`${actions.setCases.failure}`]: (state) => ({
    ...state,
    loading: false,
  }),
  [`${actions.setCaseMetaData.loading}`]: (state) => ({
    ...state,
    caseMetaDataLoading: true,
  }),
  [`${actions.setCaseMetaData.success}`]: (state, { payload }) => ({
    ...state,
    caseMetadata: payload,
    caseMetaDataLoading: false,
  }),
  [`${actions.setCaseMetaData.failure}`]: (state) => ({
    ...state,
    caseMetaDataLoading: false,
  }),
  [`${actions.setCaseDetail.loading}`]: (state) => ({
    ...state,
    caseDetailLoading: true,
  }),
  [`${actions.setCaseDetail.success}`]: (state, { payload }) => ({
    ...state,
    caseDetail: payload.data,
    caseDetailLoading: false,
  }),
  [`${actions.setCaseDetail.failure}`]: (state) => ({
    ...state,
    caseDetailLoading: false,
  }),
  [actions.setCaseTypeFilter.toString()]: (state, { payload }) => ({
    ...state,
    caseTypeFilter: payload,
  }),
  [actions.setCaseStatusFilter.toString()]: (state, { payload }) => ({
    ...state,
    caseStatusFilter: payload,
  }),
});

export const {
  useContext: useCases,
  Provider: CasesProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(casesReducer, initialState);

  const getCasesList = useCallback(
    async (page, search, caseTypeFilter = [], caseStatusFilter = []) => {
      if (page === 1 && !search && !caseStatusFilter && !caseTypeFilter) {
        dispatch(actions.setCases.loading());
      }
      try {
        const { data } = await api(`/attorney-cases`, {
          method: "get",
          params: {
            mode: "paginate",
            page: page,
            per_page: 10,
            ...(search && { q: search }),
            case_type_id: caseTypeFilter.map((c: FilterType) => Number(c?.id)),
            case_status_id: caseStatusFilter.map((c: FilterType) =>
              Number(c?.id)
            ),
          },
        });
        dispatch(actions.setCases.success(data.data));
      } catch (e) {
        dispatch(actions.setCases.failure());
      }
    },
    []
  );

  const getCaseMetadata = useCallback(async () => {
    dispatch(actions.setCaseMetaData.loading());
    try {
      const { data } = await api(`/attorney-cases/metadata`, {
        method: "get",
      });
      dispatch(actions.setCaseMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setCaseMetaData.failure());
    }
  }, []);

  const setCaseTypeFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.caseMetadata?.case_types?.find(
          (item: CaseType) => item?.case_type_id === id
        )?.case_type;
        dispatch(
          actions.setCaseTypeFilter([...state.caseTypeFilter, { id, name }])
        );
      } else {
        dispatch(
          actions.setCaseTypeFilter(
            state.caseTypeFilter.filter((item: FilterType) => item.id !== id)
          )
        );
      }
    },
    [state]
  );

  const setCaseStatusFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.caseMetadata?.case_status?.find(
          (item: CaseStatusType) => item?.case_status_id === id
        )?.case_status_name;
        dispatch(
          actions.setCaseStatusFilter([...state.caseStatusFilter, { id, name }])
        );
      } else {
        dispatch(
          actions.setCaseStatusFilter(
            state.caseStatusFilter.filter((item) => item?.id !== id)
          )
        );
      }
    },
    [state]
  );

  const setAllCaseStatusFilter = useCallback(async (caseStatus) => {
    dispatch(actions.setCaseStatusFilter(caseStatus));
  }, []);

  const setAllCaseTypeFilter = useCallback(async (caseType) => {
    dispatch(actions.setCaseTypeFilter(caseType));
  }, []);

  const resetCaseFilter = useCallback(async () => {
    dispatch(actions.setCaseStatusFilter([]));
    dispatch(actions.setCaseTypeFilter([]));
  }, []);

  const getCaseDetail = useCallback(async (id) => {
    try {
      const { data } = await api(`/attorney-cases/${id}`, {
        method: "get",
      });
      dispatch(actions.setCaseDetail.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setCaseDetail.failure());
    }
  }, []);

  return {
    state,
    actions: {
      getCasesList,
      getCaseDetail,
      getCaseMetadata,
      setCaseTypeFilter,
      setCaseStatusFilter,
      resetCaseFilter,
      setAllCaseStatusFilter,
      setAllCaseTypeFilter,
    },
  };
});
