import { useCallback, useReducer } from "react";
import { api } from "utils/api/api";
import { toast } from "react-toastify";
import {
  createContainer,
  createReducer,
  createAsyncActions,
  createAction,
} from "utils/context";
import { ClientData, ClientType } from "typings/client";
import { FilterType } from "typings/claim";

export type MyClientsContextType = {
  clients?: ClientData[];
  loading: boolean;
  total: number;
  clientDetail?: ClientData;
  clientDetailLoading: boolean;
  clientMetaData?: any;
  clientMetaDataLoading?: boolean;
  clientTypeFilter: FilterType[];
};

const initialState: MyClientsContextType = {
  loading: true,
  total: 0,
  clientDetailLoading: true,
  clientMetaDataLoading: true,
  clientTypeFilter: [],
};

const actions = {
  setClients: createAsyncActions("SET_CLIENTS"),
  setClientMetaData: createAsyncActions("SET_CLIENT_META_DATA"),
  setClientDetail: createAsyncActions("SET_CLIENT_DETAIL"),
  setClientTypeFilter: createAction("SET_CLIENT_TYPE_FILTER"),
};

const clientsReducer = createReducer<MyClientsContextType>({
  [`${actions.setClients.loading}`]: (state) => ({
    ...state,
    loading: true,
  }),
  [`${actions.setClients.success}`]: (state, { payload }) => ({
    ...state,
    clients: payload.data,
    total: payload.total,
    loading: false,
  }),
  [`${actions.setClients.failure}`]: (state) => ({
    ...state,
    loading: false,
  }),
  [`${actions.setClientDetail.loading}`]: (state) => ({
    ...state,
    clientDetailLoading: true,
  }),
  [`${actions.setClientDetail.success}`]: (state, { payload }) => ({
    ...state,
    clientDetail: payload.data,
    clientDetailLoading: false,
  }),
  [`${actions.setClientDetail.failure}`]: (state) => ({
    ...state,
    clientDetailLoading: false,
  }),
  [`${actions.setClientMetaData.loading}`]: (state) => ({
    ...state,
    clientMetaDataLoading: true,
  }),
  [`${actions.setClientMetaData.success}`]: (state, { payload }) => ({
    ...state,
    clientMetaData: payload,
    clientMetaDataLoading: false,
  }),
  [`${actions.setClientMetaData.failure}`]: (state) => ({
    ...state,
    clientMetaDataLoading: false,
  }),
  [actions.setClientTypeFilter.toString()]: (state, { payload }) => ({
    ...state,
    clientTypeFilter: payload,
  }),
});

export const {
  useContext: useClients,
  Provider: ClientsProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(clientsReducer, initialState);

  const getClientsList = useCallback(
    async (page, search, is_active, clientTypeFilter) => {
      if (page === 1 && !search && !clientTypeFilter) {
        dispatch(actions.setClients.loading());
      }
      try {
        const { data } = await api(`/attorney-clients`, {
          method: "get",
          params: {
            mode: "paginate",
            page: page,
            per_page: 10,
            ...(search && { q: search }),
            ...(is_active !== "all_clients" && {
              is_active: is_active === "active" ? 1 : 0,
            }),
            attorney_client_type_id: clientTypeFilter.map((c: FilterType) =>
              Number(c?.id)
            ),
          },
        });
        dispatch(actions.setClients.success(data.data));
      } catch (e) {
        dispatch(actions.setClients.failure());
      }
    },
    []
  );

  const getClientMetadata = useCallback(async () => {
    dispatch(actions.setClientMetaData.loading());
    try {
      const { data } = await api(`/attorney-clients/metadata`, {
        method: "get",
      });
      dispatch(actions.setClientMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setClientMetaData.failure());
    }
  }, []);

  const setClientTypeFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.clientMetaData?.attorney_client_types?.find(
          (item: ClientType) => item?.attorney_client_type_id === id
        )?.attorney_client_type_name;
        dispatch(
          actions.setClientTypeFilter([...state.clientTypeFilter, { id, name }])
        );
      } else {
        dispatch(
          actions.setClientTypeFilter(
            state.clientTypeFilter.filter((item: FilterType) => item.id !== id)
          )
        );
      }
    },
    [state]
  );

  const setAllClientTypeFilter = useCallback(async (filterArray) => {
    dispatch(actions.setClientTypeFilter(filterArray));
  }, []);

  const resetCaseFilter = useCallback(async () => {
    dispatch(actions.setClientTypeFilter([]));
  }, []);

  const getClientDetail = useCallback(async (id) => {
    try {
      const { data } = await api(`/attorney-clients/${id}`, {
        method: "get",
      });
      dispatch(actions.setClientDetail.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setClientDetail.failure());
    }
  }, []);

  return {
    state,
    actions: {
      getClientsList,
      getClientDetail,
      getClientMetadata,
      setClientTypeFilter,
      setAllClientTypeFilter,
      resetCaseFilter,
    },
  };
});
