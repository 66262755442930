import React from "react";
import styled from "styled-components";

import { Box } from "../Box";

const LoaderStyle = styled(Box)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface LoaderProps {
  children?: any;
  loading?: boolean;
}
export const Loader = ({ loading, children }: LoaderProps) => {
  if (loading) {
    return (
      <LoaderStyle
        border={1}
        borderColor="primary.500"
        borderTop="0.2rem solid primary.500"
        borderRadius="50%"
        width="2.5rem"
        height="2.5rem"
        maxWidth="100%"
        maxHeight="100%"
        overflow="auto"
        position="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        margin="auto"
      />
    );
  }
  return <>{children || null} </>;
};

Loader.defaultProps = {
  loading: true,
};
