import React, { FC } from "react";
import { Box, BoxProps } from "atoms/Box";

export const Grid: FC<BoxProps> = (props) => {
  return (
    <Box display="grid" {...props}>
      {props.children}
    </Box>
  );
};
