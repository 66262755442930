import { useCallback, useReducer, useEffect } from "react";
import { createContainer, createReducer, createAction } from "utils/context";
import { saveCookie } from "utils/cookie";
import { handleLogout, AUTH_KEYS } from "utils/logout";
import { AUTH_TOKEN_COOKIE } from "utils/api/getTokens";
import { UserData } from "typings/user";
import { hydrate, persist } from "utils/persist";
import { api } from "utils/api/api";
import { toast } from "react-toastify";

export type AuthState = {
  token?: string;
  user?: UserData;
  isLoggedIn?: boolean;
  authenticating?: boolean;
};
const initialState: AuthState = {
  isLoggedIn: false,
  authenticating: true,
};
const actions = {
  setAuthenticationState: createAction("SET_AUTHENTICATION"),
  setAuthenticating: createAction("SET_AUTHENTICATING"),
  resetUserInfo: createAction("RESET_USER_INFO"),
};

const authReducer = createReducer<AuthState>({
  [actions.setAuthenticationState.toString()]: (state, { payload }) => ({
    ...state,
    authenticating: false,
    ...payload,
  }),
  [actions.resetUserInfo.toString()]: () => ({
    ...initialState,
    authenticating: false,
  }),
  [actions.setAuthenticating.toString()]: (state, { payload }) => ({
    ...state,
    authenticating: payload,
  }),
});

export const {
  useContext: useAuth,
  Context: AuthContext,
  Provider: AuthProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setAunthenticateState = useCallback((status) => {
    dispatch(actions.setAuthenticating(status));
  }, []);

  const logout = useCallback(() => {
    handleLogout();
    dispatch(actions.resetUserInfo());
  }, []);

  const setUserInfo = useCallback(
    (token?: string, userData?: UserData) => {
      if (userData && token) {
        saveCookie(AUTH_TOKEN_COOKIE, token);
        persist(AUTH_KEYS.USER_DATA, userData, "localStorage");
        const userInfo: AuthState = {
          token,
          isLoggedIn: true,
          user: {
            ...userData,
          },
        };
        dispatch(actions.setAuthenticationState(userInfo));
      } else {
        logout();
      }
    },
    [logout]
  );

  const loginUser = useCallback(
    async (
      values: { username: string; password: string },
      onError?: (error: { message: string }) => void
    ) => {
      try {
        const { data } = await api("/clients/login", {
          method: "post",
          data: values,
        });
        if (data.data) {
          setUserInfo(
            data.data.client.user_token.auth_token,
            data.data.client.client_pass
          );
        }
      } catch (e) {
        onError?.(e);
      }
    },
    [setUserInfo]
  );

  const changePassword = useCallback(async (values, onSuccess: () => void) => {
    try {
      const { data } = await api("/clients/change-password", {
        method: "post",
        data: values,
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const forgotPassword = useCallback(async (values, onSuccess: () => void) => {
    try {
      await api("/clients/forgot-password", {
        method: "post",
        data: values,
      });
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const resetPassword = useCallback(
    async (values, verificationIdentifier, onSuccess: () => void) => {
      try {
        const { data } = await api("/clients/reset-password", {
          method: "patch",
          data: {
            _method: "PATCH",
            password: values.new_password,
            confirm_password: values.confirm_password,
            verification_identifier: verificationIdentifier,
          },
        });
        toast.success(data?.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
      }
    },
    []
  );

  useEffect(() => {
    const user = hydrate(AUTH_KEYS.USER_DATA, "localStorage") as
      | UserData
      | undefined;
    const token = hydrate(AUTH_KEYS.AUTH_TOKEN_COOKIE) as string | undefined;
    setUserInfo(token, user);
  }, [setUserInfo]);

  return {
    state,
    actions: {
      loginUser,
      logout,
      setUserInfo,
      setAunthenticateState,
      changePassword,
      forgotPassword,
      resetPassword,
    },
  };
});

export default useAuth;
