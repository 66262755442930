import React from "react";
import axios from "axios";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import { en } from "locales";
import { AuthGate } from "auth/AuthGate";
import { theme } from "utils/styles/theme";
import { composeComponents } from "utils/component";
import GlobalStyle from "./utils/styles/GlobalStyle";
import { AuthProvider } from "auth/contexts/Authentication";

import "react-toastify/dist/ReactToastify.css";
import { AppWrapper } from "templates/AppWrapper";

axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const Providers = composeComponents(
  <IntlProvider locale="en" messages={en} />,
  AuthProvider
);

const App = () => {
  return (
    <Providers>
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <ToastContainer hideProgressBar autoClose={6000} />
          <GlobalStyle />
          <Router>
            <AuthGate />
          </Router>
        </AppWrapper>
      </ThemeProvider>
    </Providers>
  );
};

export default App;
