import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { system } from "styled-system";

import { Box, BoxProps } from "atoms/Box";
import CheckIcon from "@material-ui/icons/Check";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NotificationsIcon from "@material-ui/icons/Notifications";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { ReactComponent as FilterTag } from "assets/icons/Filter.svg";
import E3_logo from "assets/icons/E3_logo";
import IBS_logo from "assets/icons/IBS_logo";
import { ReactComponent as E3FullTag } from "assets/icons/Easy, Efficient Entries_logo.svg";
import { ReactComponent as ExpenseIcon } from "assets/icons/Expense_icon.svg";
import { ReactComponent as MileageIcon } from "assets/icons/Mileage_icon.svg";
import { ReactComponent as TimeIcon } from "assets/icons/Time_icon.svg";
import { ReactComponent as TravelTimeIcon } from "assets/icons/Travel Time_icon.svg";
import { ReactComponent as ClientIcon } from "assets/icons/Client_icon.svg";
import { ReactComponent as CaseIcon } from "assets/icons/Case_icon.svg";
import { ReactComponent as ClaimIcon } from "assets/icons/Claim_icon.svg";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import MenuIcon from "@material-ui/icons/Menu";

interface IconWrapperProps extends BoxProps {
  color?: string;
}

export const IconWrapper = styled(Box)<IconWrapperProps>`
  display: grid;
  & > svg {
    height: 100%;
    width: 100%;
    ${system({
      fill: {
        property: "fill",
        scale: "colors",
      },
    })}
  }
`;

export const icons = {
  arrowBack: KeyboardArrowLeftIcon,
  arrowForward: KeyboardArrowRightIcon,
  arrowDown: KeyboardArrowDownIcon,
  arrowUp: KeyboardArrowUpIcon,
  delete: DeleteOutlineOutlinedIcon,
  edit: EditOutlinedIcon,
  notification: NotificationsIcon,
  filter: FilterTag,
  close: CloseIcon,
  add: AddIcon,
  remove: RemoveIcon,
  phone: PhoneIcon,
  mail: MailIcon,
  fileIcon: PictureAsPdfIcon,
  e3: E3_logo,
  e3Full: E3FullTag,
  ibs: IBS_logo,
  expense: ExpenseIcon,
  mileage: MileageIcon,
  time: TimeIcon,
  traveltime: TravelTimeIcon,
  client: ClientIcon,
  case: CaseIcon,
  claim: ClaimIcon,
  menuIcon: MenuIcon,
  check: CheckIcon,
};

export interface IconProps extends IconWrapperProps {
  name: keyof typeof icons;
  fill?: string;
  onClick?: () => void;
}

export const Icon: FC<IconProps> = ({
  name,
  height,
  width,
  ...props
}: IconProps) => {
  const IconSVG: any = useMemo(() => icons[name], [name]) as any;

  return (
    <IconWrapper height={height} width={width} {...props}>
      <IconSVG key={name} opacity="1" />
    </IconWrapper>
  );
};
