import { useCallback, useReducer } from "react";
import { toast } from "react-toastify";
import { AddOffenseType, AddChildType } from "typings/claim";
import {
  AddCaseMetadata,
  AddClaimMetadata,
  AdditionalClaimMetaData,
  AdditionalRepresentationMetaData,
  ClaimClientRelationshipOptions,
  InfoFormMetaData,
} from "typings/intake";
import { api } from "utils/api/api";
import {
  createContainer,
  createReducer,
  createAsyncActions,
  createAction,
} from "utils/context";

export type AddIntakeContextType = {
  clientDetailId?: number;
  clientLoading: boolean;
  caseAddMetadata?: AddCaseMetadata;
  caseAddMetaDataLoading: boolean;
  caseDetailId?: number;
  claimAddMetadata?: AddClaimMetadata;
  claimAdditionalMetadata?: AdditionalClaimMetaData;
  claimAddMetaDataLoading: boolean;
  claimDetailId?: number;
  addChildValues: AddChildType[];
  addOffensesValues: AddOffenseType[];
  formType: string;
  metadataAdditionalClaim?: InfoFormMetaData;
  claimAdditionalRepresentationMetadata?: AdditionalRepresentationMetaData;
  metadataAdditionalClaimCleint?: ClaimClientRelationshipOptions[];
};

const initialState: AddIntakeContextType = {
  clientLoading: true,
  caseAddMetaDataLoading: true,
  claimAddMetaDataLoading: true,
  addChildValues: [],
  addOffensesValues: [],
  formType: "",
};

const actions = {
  setNewClientProfile: createAsyncActions("SET_CLIENT_DETAIL"),
  setUpdatedClientProfile: createAsyncActions("SET_UPDATED_CLIENT_DETAIL"),
  setAddCaseMetaData: createAsyncActions("SET_CASE_META_DATA"),
  setNewCaseDetail: createAsyncActions("SET_NEW_CASE_DETAIL"),
  setUpdatedCaseProfile: createAsyncActions("SET_UPDATED_CASE_DETAIL"),
  setAddClaimMetaData: createAsyncActions("SET_CLAIM_META_DATA"),
  setNewClaimDetail: createAsyncActions("SET_NEW_CLAIM_DETAIL"),
  setUpdatedClaimDetails: createAsyncActions("SET_UPDATED_CLAIM_DETAIL"),
  deleteCaseFile: createAction("DELETE_CASE_FILE"),
  deleteClaimFile: createAction("DELETE_CLAIM_FILE"),
  setAdditionalClaimMetaData: createAsyncActions(
    "SET_ADDITIONAL_CLAIM_META_DATA"
  ),
  setAddChildValues: createAction("SET_ADD_CHILD_VALUES"),
  setFormType: createAction("SET_FORM_TYPE"),
  setAddOffensesValues: createAction("SET_ADD_CHARGED_OFFENSES_VALUES"),
  setMetadataOfAdditionalClaim: createAsyncActions(
    "SET_DATA_OF_ADDITIONAL_CLAIM"
  ),
  setMetadataOfAdditionalClaimCleint: createAsyncActions(
    "SET_DATA_OF_ADDITIONAL_CLAIM_CLIENT"
  ),
  setAdditionalClaimRepresentaionMetaData: createAsyncActions(
    "SET_ADDITIONAL_CLAIM_REPRESENTATION_META_DATA"
  ),
};

const claimsReducer = createReducer<AddIntakeContextType>({
  [`${actions.setNewClientProfile.loading}`]: (state) => ({
    ...state,
    clientLoading: true,
  }),
  [`${actions.setNewClientProfile.success}`]: (state, { payload }) => ({
    ...state,
    clientDetailId: payload,
    clientLoading: false,
  }),
  [`${actions.setNewClientProfile.failure}`]: (state) => ({
    ...state,
    clientLoading: false,
  }),
  [`${actions.setAddCaseMetaData.loading}`]: (state) => ({
    ...state,
    caseAddMetaDataLoading: true,
  }),
  [`${actions.setAddCaseMetaData.success}`]: (state, { payload }) => ({
    ...state,
    caseAddMetadata: payload,
    caseAddMetaDataLoading: false,
  }),
  [`${actions.setAddCaseMetaData.failure}`]: (state) => ({
    ...state,
    caseAddMetaDataLoading: false,
  }),
  [`${actions.setNewCaseDetail.success}`]: (state, { payload }) => ({
    ...state,
    caseDetailId: payload,
  }),
  [`${actions.setAddClaimMetaData.loading}`]: (state) => ({
    ...state,
    claimAddMetaDataLoading: true,
  }),
  [`${actions.setAddClaimMetaData.success}`]: (state, { payload }) => ({
    ...state,
    claimAddMetadata: payload,
    claimAddMetaDataLoading: false,
  }),
  [`${actions.setAddClaimMetaData.failure}`]: (state) => ({
    ...state,
    claimAddMetaDataLoading: false,
  }),
  [`${actions.setNewClaimDetail.success}`]: (state, { payload }) => ({
    ...state,
    claimDetailId: payload,
  }),
  [`${actions.setAdditionalClaimMetaData.success}`]: (state, { payload }) => ({
    ...state,
    claimAdditionalMetadata: payload,
  }),
  [actions.setAddChildValues.toString()]: (state, { payload }) => ({
    ...state,
    addChildValues: payload,
  }),
  [actions.setFormType.toString()]: (state, { payload }) => ({
    ...state,
    formType: payload,
  }),
  [actions.setAddOffensesValues.toString()]: (state, { payload }) => ({
    ...state,
    addOffensesValues: payload,
  }),
  [`${actions.setMetadataOfAdditionalClaim.success}`]: (
    state,
    { payload }
  ) => ({
    ...state,
    metadataAdditionalClaim: payload,
  }),
  [`${actions.setMetadataOfAdditionalClaimCleint.success}`]: (
    state,
    { payload }
  ) => ({
    ...state,
    metadataAdditionalClaimCleint: payload,
  }),
  [`${actions.setAdditionalClaimRepresentaionMetaData.success}`]: (
    state,
    { payload }
  ) => ({
    ...state,
    claimAdditionalRepresentationMetadata: payload,
  }),
});

export const {
  useContext: useAddIntake,
  Provider: AddIntakeProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(claimsReducer, initialState);

  // Client Intake
  const setNewClientProfile = useCallback(
    async (formData, onSuccess: () => void) => {
      dispatch(actions.setNewClientProfile.loading());
      try {
        const { data } = await api(`/attorney-clients/`, {
          method: "post",
          data: { ...formData },
        });
        dispatch(
          actions.setNewClientProfile.success(data?.data?.attorney_client_id)
        );
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setNewClientProfile.failure());
      }
    },
    []
  );

  const setUpdatedClientProfile = useCallback(
    async (id: number, formData, onSuccess: () => void) => {
      dispatch(actions.setUpdatedClientProfile.loading());
      try {
        const { data } = await api(`/attorney-clients/${id}`, {
          method: "POST",
          data: { _method: "PATCH", ...formData },
        });
        dispatch(actions.setUpdatedClientProfile.success(data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setUpdatedClientProfile.failure());
      }
      return undefined;
    },
    []
  );

  // Case Intake
  const getAddCaseMetadata = useCallback(async () => {
    dispatch(actions.setAddCaseMetaData.loading());
    try {
      const { data } = await api(`/attorney-cases/add-metadata`, {
        method: "get",
      });
      dispatch(actions.setAddCaseMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setAddCaseMetaData.failure());
    }
  }, []);

  const setNewCaseDetail = useCallback(
    async (formData, onSuccess: () => void) => {
      dispatch(actions.setNewCaseDetail.loading());
      try {
        const { data } = await api(`/attorney-cases/`, {
          method: "post",
          data: formData,
        });
        dispatch(
          actions.setNewCaseDetail.success(data?.data?.attorney_client_case_id)
        );
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setNewCaseDetail.failure());
      }
    },
    []
  );

  const setUpdatedCaseProfile = useCallback(
    async (id: number, formData, onSuccess: () => void) => {
      dispatch(actions.setUpdatedCaseProfile.loading());
      try {
        const { data } = await api(`/attorney-cases/${id}`, {
          method: "POST",
          data: formData,
        });
        dispatch(actions.setUpdatedCaseProfile.success(data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setUpdatedCaseProfile.failure());
      }
      return undefined;
    },
    []
  );

  const deleteCaseFile = useCallback(async (id, onSuccess: () => void) => {
    try {
      const { data } = await api(`/case-documents/${id}`, {
        method: "delete",
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  // Claim Intake
  const getAddClaimMetadata = useCallback(async (id: number) => {
    dispatch(actions.setAddClaimMetaData.loading());
    try {
      const { data } = await api(`/attorney-claims/add-metadata`, {
        method: "get",
        params: {
          attorney_case_id: id,
        },
      });
      dispatch(actions.setAddClaimMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setAddClaimMetaData.failure());
    }
  }, []);

  const setNewClaimDetail = useCallback(
    async (formData, onSuccess: () => void) => {
      dispatch(actions.setNewClaimDetail.loading());
      try {
        const { data } = await api(`/attorney-claims/`, {
          method: "post",
          data: formData,
        });
        dispatch(
          actions.setNewClaimDetail.success(data?.data?.attorney_claim_id)
        );
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setNewClaimDetail.failure());
      }
    },
    []
  );

  const setUpdatedClaimDetails = useCallback(
    async (id: number, formData, onSuccess: () => void) => {
      dispatch(actions.setUpdatedClaimDetails.loading());
      try {
        const { data } = await api(`/attorney-claims/${id}`, {
          method: "POST",
          data: formData,
        });
        dispatch(actions.setUpdatedClaimDetails.success(data));
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
        dispatch(actions.setUpdatedClaimDetails.failure());
      }
      return undefined;
    },
    []
  );

  const deleteClaimFile = useCallback(async (id, onSuccess: () => void) => {
    try {
      const { data } = await api(`/claim-documents/${id}`, {
        method: "delete",
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const getAdditionalClaimMetadata = useCallback(
    async (caseId: number, claimId?: number) => {
      dispatch(actions.setAdditionalClaimMetaData.loading());
      try {
        const { data } = await api(
          `/attorney-claims/add-additional-info-metadata`,
          {
            method: "get",
            params: {
              attorney_client_case_id: caseId,
              claim_type_id: claimId,
            },
          }
        );
        dispatch(
          actions.setAdditionalClaimMetaData.success(
            data.data?.case_claim_rules
          )
        );
        dispatch(
          actions.setAdditionalClaimRepresentaionMetaData.success(
            data.data?.case_representation_rules
          )
        );
      } catch (e) {
        dispatch(actions.setAdditionalClaimMetaData.failure());
      }
    },
    []
  );

  const getMetadataOfAdditionalClaim = useCallback(
    async (scope: string, caseId?: number, representationId?: number) => {
      dispatch(actions.setMetadataOfAdditionalClaim.loading());
      try {
        const { data } = await api(`/attorney-claims/add-info-form-metadata`, {
          method: "get",
          params: {
            scope: scope,
            attorney_case_id: caseId,
            ...(representationId && { representation_id: representationId }),
          },
        });

        if (data.data?.client_relationship_options) {
          dispatch(
            actions.setMetadataOfAdditionalClaimCleint.success(
              data.data?.client_relationship_options
            )
          );
        } else {
          dispatch(actions.setMetadataOfAdditionalClaim.success(data.data));
        }
      } catch (e) {
        dispatch(actions.setMetadataOfAdditionalClaim.failure());
      }
    },
    []
  );

  // ---Set and reset Form Type for Additional Information
  const setFormType = useCallback(async (formType: string) => {
    dispatch(actions.setFormType(formType));
  }, []);

  const resetFormType = useCallback(async () => {
    dispatch(actions.setFormType(""));
  }, []);

  // ---API's for AddChildIntake
  const setAddChildValues = useCallback(
    async (formData?: AddChildType) => {
      dispatch(actions.setAddChildValues([...state.addChildValues, formData]));
    },
    [state]
  );

  const setRemoveChildValues = useCallback(
    async (childIndex?: number) => {
      const newChildArray = [...state?.addChildValues];
      newChildArray.splice(Number(childIndex), 1);
      dispatch(actions.setAddChildValues(newChildArray));
    },
    [state]
  );

  const setEditChildValues = useCallback(
    async (formData: AddChildType, index: number) => {
      const editArray = [...state?.addChildValues];
      editArray[index] = formData;
      dispatch(actions.setAddChildValues(editArray));
    },
    [state]
  );

  const resetChildValues = useCallback(async () => {
    dispatch(actions.setAddChildValues([]));
  }, []);

  // ---API's for AddChargedOffencesIntake
  const setAddOffensesValues = useCallback(
    async (formData?: AddOffenseType) => {
      dispatch(
        actions.setAddOffensesValues([...state.addOffensesValues, formData])
      );
    },
    [state]
  );

  const setRemoveOffensesValues = useCallback(
    async (offenseIndex?: number) => {
      const newOffenseArray = [...state?.addOffensesValues];
      newOffenseArray.splice(Number(offenseIndex), 1);
      dispatch(actions.setAddOffensesValues(newOffenseArray));
    },
    [state]
  );

  const setEditOffensesValues = useCallback(
    async (formData: AddOffenseType, index: number) => {
      const editArray = [...state?.addOffensesValues];
      editArray[index] = formData;
      dispatch(actions.setAddOffensesValues(editArray));
    },
    [state]
  );

  const resetOffensesValues = useCallback(async () => {
    dispatch(actions.setAddOffensesValues([]));
  }, []);

  // ---API's for Add, Edit and Remove Child Details - Edit Section

  const deleteChildInfo = useCallback(async (id, onSuccess: () => void) => {
    try {
      const { data } = await api(`/children/${id}`, {
        method: "delete",
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const editChildInfo = useCallback(
    async (id, formData, onSuccess: () => void) => {
      try {
        const { data } = await api(`/children/${id}`, {
          method: "POST",
          data: { _method: "PATCH", ...formData },
        });
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
      }
    },
    []
  );

  const addChildInfo = useCallback(async (formData, onSuccess: () => void) => {
    try {
      const { data } = await api(`/children`, {
        method: "POST",
        data: formData,
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  // ---API's for Add, Edit and Remove Offense Details - Edit Section

  const deleteOffenseInfo = useCallback(async (id, onSuccess: () => void) => {
    try {
      const { data } = await api(`/offences/${id}`, {
        method: "delete",
      });
      toast.success(data.message);
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const editOffenseInfo = useCallback(
    async (id, formData, onSuccess: () => void) => {
      try {
        const { data } = await api(`/offences/${id}`, {
          method: "POST",
          data: { _method: "PATCH", ...formData },
        });
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
      }
    },
    []
  );

  const addOffenseInfo = useCallback(
    async (formData, onSuccess: () => void) => {
      try {
        const { data } = await api(`/offences`, {
          method: "POST",
          data: formData,
        });
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
      }
    },
    []
  );

  return {
    state,
    actions: {
      setNewClientProfile,
      setUpdatedClientProfile,
      getAddCaseMetadata,
      setNewCaseDetail,
      setUpdatedCaseProfile,
      getAddClaimMetadata,
      setNewClaimDetail,
      setUpdatedClaimDetails,
      deleteCaseFile,
      deleteClaimFile,
      getAdditionalClaimMetadata,
      setAddChildValues,
      setRemoveChildValues,
      setEditChildValues,
      deleteChildInfo,
      editChildInfo,
      resetChildValues,
      addChildInfo,
      setFormType,
      resetFormType,
      setAddOffensesValues,
      setRemoveOffensesValues,
      setEditOffensesValues,
      resetOffensesValues,
      deleteOffenseInfo,
      editOffenseInfo,
      addOffenseInfo,
      getMetadataOfAdditionalClaim,
    },
  };
});
