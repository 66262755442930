import { get } from "lodash";
import { toast } from "react-toastify";
import UAParser from "ua-parser-js";

/* eslint-disable no-console */

const google = get(window, "google");

export const log = (e: any) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(e);
  }
};
export const logError = (e: any) => {
  if (process.env.NODE_ENV !== "production") {
    console.error(e);
  }
};

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const getPlace = (element: any, setFieldValue: any) => {
  const autocomplete = new google.maps.places.Autocomplete(element);
  autocomplete.addListener("place_changed", () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      setFieldValue("latitude", place.geometry.location.lat());
      setFieldValue("longitude", place.geometry.location.lng());
      setFieldValue("address", place.formatted_address);
    }

    return false;
  });
};

export const copyToClipBoard = async (copyMe: string) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    toast.success("Copied!");
  } catch (err) {
    logError(err);
  }
};

type Types = {};

interface GoogleLocationProps {
  types: Array<Types>;
  long_name: string; // eslint-disable-line camelcase
  short_name: string; // eslint-disable-line camelcase
}

export const getPlaces = (element: any, values: any, setValues: any) => {
  const autocomplete = new google.maps.places.Autocomplete(element);
  autocomplete.addListener("place_changed", () => {
    const places = autocomplete.getPlace();
    if (places.geometry) {
      setValues({
        ...values,
        business_name:
          element.id === "business_name" ? places.name : values.business_name,
        business_address: places.formatted_address,
        business_city_id: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "locality")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        city_name: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "locality")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        state_code: places.address_components
          .filter(
            (obj: GoogleLocationProps) =>
              obj.types[0] === "administrative_area_level_1"
          )
          .map((obj: GoogleLocationProps) => obj.short_name)[0],
        business_state_id: places.address_components
          .filter(
            (obj: GoogleLocationProps) =>
              obj.types[0] === "administrative_area_level_1"
          )
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        zipcode: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "postal_code")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        latitude: places.geometry.location.lat(),
        longitude: places.geometry.location.lng(),
      });
    }
    return false;
  });
};

export const getDealPlaces = (element: any, values: any, setValues: any) => {
  const autocomplete = new google.maps.places.Autocomplete(element);
  autocomplete.addListener("place_changed", () => {
    const places = autocomplete.getPlace();
    if (places.geometry) {
      setValues({
        ...values,
        deal_business_name:
          element.id === "deal_business_name"
            ? places.name
            : values.deal_business_name,
        deal_business_address: places.formatted_address,
        deal_business_city_id: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "locality")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        deal_business_state_id: places.address_components
          .filter(
            (obj: GoogleLocationProps) =>
              obj.types[0] === "administrative_area_level_1"
          )
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        deal_zipcode: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "postal_code")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        deal_latitude: places.geometry.location.lat(),
        deal_longitude: places.geometry.location.lng(),
      });
    }
    return false;
  });
};
export const getResidentPlaces = (
  element: any,
  values: any,
  setValues: any
) => {
  const autocomplete = new google.maps.places.Autocomplete(element);
  autocomplete.addListener("place_changed", () => {
    const places = autocomplete.getPlace();
    if (places.geometry) {
      setValues({
        ...values,
        resident_address: places.formatted_address,
        resident_city_id: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "locality")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        resident_state_id: places.address_components
          .filter(
            (obj: GoogleLocationProps) =>
              obj.types[0] === "administrative_area_level_1"
          )
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
        resident_zipcode: places.address_components
          .filter((obj: GoogleLocationProps) => obj.types[0] === "postal_code")
          .map((obj: GoogleLocationProps) => obj.long_name)[0],
      });
    }
    return false;
  });
};

export const normalizePhone = (value: string) => {
  const onlyNums = value ? value.replace(/[^\d]/g, "") : "";
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

export const deformatPhone = (value: string) => {
  const onlyNums = `${value}`;
  return onlyNums.replace(/[^\d]/g, "");
};

export const deviceInformation = () => {
  // @ts-ignore
  const results = new UAParser().getResult();

  return {
    device: results.os.name,
    platform: results.browser.name,
    platform_version: results.browser.version,
  };
};

export const CapitalizeValue = (value: string) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
};

export const StateValues = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
