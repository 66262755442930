import { useCallback, useReducer } from "react";
import { api } from "utils/api/api";
import { toast } from "react-toastify";
import {
  createContainer,
  createReducer,
  createAsyncActions,
  createAction,
} from "utils/context";
import {
  ClaimData,
  Courts,
  FilterType,
  IntakeReviewStatus,
  BillingEntryStatus,
} from "typings/claim";
import { CaseType } from "typings/client";
import { RepresentationType } from "typings/case";

export type MyClaimsContextType = {
  claims?: ClaimData[];
  loading: boolean;
  total: number;
  claimDetail?: ClaimData;
  claimDetailLoading: boolean;
  claimMetadata?: any;
  claimMetaDataLoading: boolean;
  claimCaseTypeFilter: FilterType[];
  claimCourtTypeFilter: FilterType[];
  claimIntakeStatusFilter: FilterType[];
  claimBillingStatusFilter: FilterType[];
  claimRepresentationFilter: FilterType[];
};

const initialState: MyClaimsContextType = {
  loading: true,
  total: 0,
  claimDetailLoading: true,
  claimMetaDataLoading: true,
  claimCaseTypeFilter: [],
  claimCourtTypeFilter: [],
  claimIntakeStatusFilter: [],
  claimBillingStatusFilter: [],
  claimRepresentationFilter: [],
};

const actions = {
  setClaims: createAsyncActions("SET_CLAIMS"),
  setClaimMetaData: createAsyncActions("SET_CLAIM_META_DATA"),
  setClaimDetail: createAsyncActions("SET_CLAIM_DETAIL"),
  setClaimCaseTypeFilter: createAction("SET_CLAIM_TYPE_FILTER"),
  setClaimCourtTypeFilter: createAction("SET_CLAIM_COURT_TYPE_FILTER"),
  setClaimIntakeStatusFilter: createAction(
    "SET_CLAIM_INTAKE_REVIEW_STATUS_FILTER"
  ),
  setClaimBillingStatusFilter: createAction(
    "SET_CLAIM_BILLING_ENTRY_STATUS_FILTER"
  ),
  setClaimRepresentationFilter: createAction("SET_CLAIM_REPRESENTATION_FILTER"),
};

const claimsReducer = createReducer<MyClaimsContextType>({
  [`${actions.setClaims.loading}`]: (state) => ({
    ...state,
    loading: true,
  }),
  [`${actions.setClaims.success}`]: (state, { payload }) => ({
    ...state,
    claims: payload.data,
    total: payload.total,
    loading: false,
  }),
  [`${actions.setClaims.failure}`]: (state) => ({
    ...state,
    loading: false,
  }),
  [`${actions.setClaimMetaData.loading}`]: (state) => ({
    ...state,
    claimMetaDataLoading: true,
  }),
  [`${actions.setClaimMetaData.success}`]: (state, { payload }) => ({
    ...state,
    claimMetadata: payload,
    claimMetaDataLoading: false,
  }),
  [`${actions.setClaimMetaData.failure}`]: (state) => ({
    ...state,
    claimMetaDataLoading: false,
  }),
  [`${actions.setClaimDetail.loading}`]: (state) => ({
    ...state,
    claimDetailLoading: true,
  }),
  [`${actions.setClaimDetail.success}`]: (state, { payload }) => ({
    ...state,
    claimDetail: payload.data,
    claimDetailLoading: false,
  }),
  [`${actions.setClaimDetail.failure}`]: (state) => ({
    ...state,
    claimDetailLoading: false,
  }),
  [actions.setClaimCaseTypeFilter.toString()]: (state, { payload }) => ({
    ...state,
    claimCaseTypeFilter: payload,
  }),
  [actions.setClaimCourtTypeFilter.toString()]: (state, { payload }) => ({
    ...state,
    claimCourtTypeFilter: payload,
  }),
  [actions.setClaimIntakeStatusFilter.toString()]: (state, { payload }) => ({
    ...state,
    claimIntakeStatusFilter: payload,
  }),
  [actions.setClaimBillingStatusFilter.toString()]: (state, { payload }) => ({
    ...state,
    claimBillingStatusFilter: payload,
  }),
  [actions.setClaimRepresentationFilter.toString()]: (state, { payload }) => ({
    ...state,
    claimRepresentationFilter: payload,
  }),
});

export const {
  useContext: useClaims,
  Provider: ClaimsProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(claimsReducer, initialState);

  const getClaimsList = useCallback(
    async (
      page,
      search,

      claimCaseTypeFilter,
      claimCourtTypeFilter,
      claimIntakeStatusFilter,
      claimBillingStatusFilter,
      claimRepresentationFilter
    ) => {
      if (
        page === 1 &&
        !search &&
        !claimCaseTypeFilter &&
        !claimCourtTypeFilter &&
        !claimIntakeStatusFilter &&
        !claimBillingStatusFilter &&
        !claimRepresentationFilter
      ) {
        dispatch(actions.setClaims.loading());
      }
      try {
        const { data } = await api(`/attorney-claims`, {
          method: "get",
          params: {
            mode: "paginate",
            page: page,
            per_page: 10,
            ...(search && { q: search }),
            case_type_id: claimCaseTypeFilter.map((c: FilterType) =>
              Number(c?.id)
            ),
            court_id: claimCourtTypeFilter.map((c: FilterType) =>
              Number(c?.id)
            ),
            intake_review_status_id: claimIntakeStatusFilter.map(
              (c: FilterType) => Number(c?.id)
            ),
            billing_entry_status_id: claimBillingStatusFilter.map(
              (c: FilterType) => Number(c?.id)
            ),
            representation_id: claimRepresentationFilter.map((c: FilterType) =>
              Number(c?.id)
            ),
          },
        });
        dispatch(actions.setClaims.success(data.data));
      } catch (e) {
        dispatch(actions.setClaims.failure());
      }
    },
    []
  );

  const getClaimMetadata = useCallback(async () => {
    dispatch(actions.setClaimMetaData.loading());
    try {
      const { data } = await api(`/attorney-claims/metadata`, {
        method: "get",
      });
      dispatch(actions.setClaimMetaData.success(data.data));
    } catch (e) {
      dispatch(actions.setClaimMetaData.failure());
    }
  }, []);

  const setClaimCaseTypeFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.claimMetadata?.case_types?.find(
          (item: CaseType) => item?.case_type_id === id
        )?.case_type;
        dispatch(
          actions.setClaimCaseTypeFilter([
            ...state.claimCaseTypeFilter,
            { id, name },
          ])
        );
      } else {
        dispatch(
          actions.setClaimCaseTypeFilter(
            state.claimCaseTypeFilter.filter(
              (item: FilterType) => item.id !== id
            )
          )
        );
      }
    },
    [state]
  );

  const setClaimCourtTypeFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.claimMetadata?.courts?.find(
          (item: Courts) => item.court_id === id
        )?.court_name;
        dispatch(
          actions.setClaimCourtTypeFilter([
            ...state.claimCourtTypeFilter,
            { id, name },
          ])
        );
      } else {
        dispatch(
          actions.setClaimCourtTypeFilter(
            state.claimCourtTypeFilter.filter(
              (item: FilterType) => item.id !== id
            )
          )
        );
      }
    },
    [state]
  );

  const setClaimIntakeStatusFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.claimMetadata?.intake_review_status?.find(
          (item: IntakeReviewStatus) => item.intake_review_status_id === id
        )?.intake_review_status_name;
        dispatch(
          actions.setClaimIntakeStatusFilter([
            ...state.claimIntakeStatusFilter,
            { id, name },
          ])
        );
      } else {
        dispatch(
          actions.setClaimIntakeStatusFilter(
            state.claimIntakeStatusFilter.filter(
              (item: FilterType) => item.id !== id
            )
          )
        );
      }
    },
    [state]
  );

  const setClaimBillingStatusFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.claimMetadata?.biiling_entry_status?.find(
          (item: BillingEntryStatus) => item.billing_entry_status_id === id
        )?.billing_entry_status_name;
        dispatch(
          actions.setClaimBillingStatusFilter([
            ...state.claimBillingStatusFilter,
            { id, name },
          ])
        );
      } else {
        dispatch(
          actions.setClaimBillingStatusFilter(
            state.claimBillingStatusFilter.filter(
              (item: FilterType) => item.id !== id
            )
          )
        );
      }
    },
    [state]
  );

  const setClaimRepresentationFilter = useCallback(
    async (id, checked) => {
      if (checked) {
        const name = state?.claimMetadata?.representations?.find(
          (item: RepresentationType) => item.representation_id === id
        )?.representation_name;
        dispatch(
          actions.setClaimRepresentationFilter([
            ...state.claimRepresentationFilter,
            { id, name },
          ])
        );
      } else {
        dispatch(
          actions.setClaimRepresentationFilter(
            state.claimRepresentationFilter.filter(
              (item: FilterType) => item.id !== id
            )
          )
        );
      }
    },
    [state]
  );

  const setAllClaimCaseTypeFilter = useCallback(async (caseStatus) => {
    dispatch(actions.setClaimCaseTypeFilter(caseStatus));
  }, []);

  const setAllClaimCourtTypeFilter = useCallback(async (caseType) => {
    dispatch(actions.setClaimCourtTypeFilter(caseType));
  }, []);
  const setAllClaimRepresentationFilter = useCallback(async (caseType) => {
    dispatch(actions.setClaimRepresentationFilter(caseType));
  }, []);

  const setAllClaimIntakeStatusFilter = useCallback(async (caseType) => {
    dispatch(actions.setClaimIntakeStatusFilter(caseType));
  }, []);

  const setAllClaimBillingStatusFilter = useCallback(async (caseType) => {
    dispatch(actions.setClaimBillingStatusFilter(caseType));
  }, []);

  const resetCaseFilter = useCallback(async () => {
    dispatch(actions.setClaimCourtTypeFilter([]));
    dispatch(actions.setClaimBillingStatusFilter([]));
    dispatch(actions.setClaimCaseTypeFilter([]));
    dispatch(actions.setClaimIntakeStatusFilter([]));
    dispatch(actions.setClaimRepresentationFilter([]));
  }, []);

  const getClaimDetail = useCallback(async (id) => {
    try {
      const { data } = await api(`/attorney-claims/${id}`, {
        method: "get",
      });
      dispatch(actions.setClaimDetail.success(data));
    } catch (e) {
      toast.error(e.message);
      dispatch(actions.setClaimDetail.failure());
    }
  }, []);

  return {
    state,
    actions: {
      getClaimsList,
      getClaimDetail,
      getClaimMetadata,
      setClaimCaseTypeFilter,
      setClaimCourtTypeFilter,
      setClaimIntakeStatusFilter,
      setClaimBillingStatusFilter,
      setClaimRepresentationFilter,
      setAllClaimRepresentationFilter,
      setAllClaimBillingStatusFilter,
      setAllClaimCaseTypeFilter,
      setAllClaimIntakeStatusFilter,
      setAllClaimCourtTypeFilter,
      resetCaseFilter,
    },
  };
});
