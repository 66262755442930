import { useCallback, useReducer } from "react";
import { api } from "utils/api/api";
import {
  createContainer,
  createReducer,
  createAsyncActions,
} from "utils/context";
import { toast } from "react-toastify";
import { Notification } from "typings/client";

export type NotificationsContextType = {
  notificationsCount: string;
  alertNotificationsCount: string;
  missingNotificationsCount: string;
  warningNotificationsCount: string;
  alertNotifications: Notification[];
  missingNotifications: Notification[];
  warningNotifications: Notification[];
  missingLoading: boolean;
  alertLoading: boolean;
  warningLoading: boolean;
};

const initialState: NotificationsContextType = {
  notificationsCount: "",
  alertNotificationsCount: "",
  missingNotificationsCount: "",
  warningNotificationsCount: "",
  alertNotifications: [],
  missingNotifications: [],
  warningNotifications: [],
  missingLoading: true,
  alertLoading: true,
  warningLoading: true,
};

const actions = {
  setNotificationsCount: createAsyncActions("SET_NOTIFICATIONS_COUNT"),
  setAlertNotificationsCount: createAsyncActions(
    "SET_ALERT_NOTIFICATIONS_COUNT"
  ),
  setMissingNotificationsCount: createAsyncActions(
    "SET_MISSING_NOTIFICATIONS_COUNT"
  ),
  setWarningNotificationsCount: createAsyncActions(
    "SET_WARNING_NOTIFICATIONS_COUNT"
  ),
  setAlertNotifications: createAsyncActions("SET_ALERT_NOTIFICATIONS"),
  setMissingNotifications: createAsyncActions("SET_MISSING_NOTIFICATIONS"),
  setWarningNotifications: createAsyncActions("SET_WARNING_NOTIFICATIONS"),
  // setNotificationAsRead: createAsyncActions("SET_NOTIFICATION_AS_READ"),
};

const notificationsReducer = createReducer<NotificationsContextType>({
  [`${actions.setNotificationsCount.success}`]: (state, { payload }) => ({
    ...state,
    notificationsCount: payload,
  }),
  [`${actions.setAlertNotificationsCount.success}`]: (state, { payload }) => ({
    ...state,
    alertNotificationsCount: payload,
  }),
  [`${actions.setMissingNotificationsCount.success}`]: (
    state,
    { payload }
  ) => ({
    ...state,
    missingNotificationsCount: payload,
  }),
  [`${actions.setWarningNotificationsCount.success}`]: (
    state,
    { payload }
  ) => ({
    ...state,
    warningNotificationsCount: payload,
  }),
  [`${actions.setMissingNotifications.loading}`]: (state) => ({
    ...state,
    alertLoading: true,
  }),
  [`${actions.setAlertNotifications.success}`]: (state, { payload }) => ({
    ...state,
    alertNotifications: payload,
    alertLoading: false,
  }),
  [`${actions.setMissingNotifications.failure}`]: (state) => ({
    ...state,
    alertLoading: false,
  }),
  [`${actions.setMissingNotifications.loading}`]: (state) => ({
    ...state,
    missingLoading: true,
  }),
  [`${actions.setMissingNotifications.success}`]: (state, { payload }) => ({
    ...state,
    missingNotifications: payload,
    missingLoading: false,
  }),
  [`${actions.setMissingNotifications.failure}`]: (state) => ({
    ...state,
    missingLoading: false,
  }),
  [`${actions.setWarningNotifications.loading}`]: (state) => ({
    ...state,
    warningLoading: true,
  }),
  [`${actions.setWarningNotifications.success}`]: (state, { payload }) => ({
    ...state,
    warningNotifications: payload,
    warningLoading: false,
  }),
  [`${actions.setWarningNotifications.failure}`]: (state) => ({
    ...state,
    warningLoading: false,
  }),
});

export const {
  useContext: useNotifications,
  Provider: NotificationsProvider,
} = createContainer(() => {
  const [state, dispatch] = useReducer(notificationsReducer, initialState);

  const getNotificationsList = useCallback(
    async (
      actionGroup?: string,
      clientID?: number,
      caseId?: number,
      claimId?: number,
      entryID?: number
    ) => {
      dispatch(actions.setNotificationsCount.loading());
      try {
        const { data } = await api(`/notifications`, {
          method: "get",
          params: {
            mode: "count",
            ...(actionGroup && { action_group: actionGroup }),
            ...(clientID && { attorney_client_id: clientID }),
            ...(caseId && { case_id: caseId }),
            ...(claimId && { claim_id: claimId }),
            ...(entryID && { entry_id: entryID }),
          },
        });
        if (actionGroup === "alert") {
          dispatch(
            actions.setAlertNotificationsCount.success(data.data.notifications)
          );
        } else if (actionGroup === "missing-info") {
          dispatch(
            actions.setMissingNotificationsCount.success(
              data.data.notifications
            )
          );
        } else if (actionGroup === "warning") {
          dispatch(
            actions.setWarningNotificationsCount.success(
              data.data.notifications
            )
          );
        }
        dispatch(
          actions.setNotificationsCount.success(data.data.notifications)
        );
      } catch (e) {
        dispatch(actions.setNotificationsCount.failure());
      }
    },
    []
  );

  const getAlertNotificationsList = useCallback(async () => {
    dispatch(actions.setAlertNotifications.loading());
    try {
      const { data } = await api(`/notifications`, {
        method: "get",
        params: {
          mode: "all",
          action_group: "alert",
        },
      });
      dispatch(actions.setAlertNotifications.success(data.data.notifications));
    } catch (e) {
      dispatch(actions.setAlertNotifications.failure());
    }
  }, []);

  const getWarningNotificationsList = useCallback(async () => {
    dispatch(actions.setWarningNotifications.loading());
    try {
      const { data } = await api(`/notifications`, {
        method: "get",
        params: {
          mode: "all",
          action_group: "warning",
        },
      });
      dispatch(
        actions.setWarningNotifications.success(data.data.notifications)
      );
    } catch (e) {
      dispatch(actions.setWarningNotifications.failure());
    }
  }, []);

  const getMissingNotificationsList = useCallback(async () => {
    dispatch(actions.setMissingNotifications.loading());
    try {
      const { data } = await api(`/notifications`, {
        method: "get",
        params: {
          mode: "all",
          action_group: "missing-info",
        },
      });
      dispatch(
        actions.setMissingNotifications.success(data.data.notifications)
      );
    } catch (e) {
      dispatch(actions.setMissingNotifications.failure());
    }
  }, []);

  const setNotificationAsRead = useCallback(
    async (id, onSuccess: () => void) => {
      try {
        const { data } = await api(`/notifications/${id}/mark-read`, {
          method: "get",
        });
        toast.success(data.message);
        onSuccess();
      } catch (e) {
        toast.error(e.message);
      }
    },
    []
  );

  return {
    state,
    actions: {
      getNotificationsList,
      getAlertNotificationsList,
      getMissingNotificationsList,
      getWarningNotificationsList,
      setNotificationAsRead,
    },
  };
});
