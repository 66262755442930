import * as React from "react";

function E3_logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 57.9 57.9" width="1em" height="1em" {...props}>
      <defs>
        <style>{".prefix__cls-1"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            className="prefix__cls-1"
            d="M31.32 40c-1.62 2.83-4.09 3.9-8.13 3.9-6.23 0-9.94-4-9.94-10.5v-3.09c0-6.6 3.11-10.41 9.2-10.41s9.2 3.77 9.2 10.41v2.42H17v.65c0 4 1.91 7.15 5.95 7.15 3 0 4.56-.88 5.53-2.6zM17 29.48h10.88c-.18-3.91-1.9-6.23-5.43-6.23S17.2 25.62 17 29.48zM38.47 20.82h1.12a2.25 2.25 0 002.57-2.39 2.13 2.13 0 00-2.27-2.11 2.18 2.18 0 00-2.28 2h-2.46A4.53 4.53 0 0140 14c2.81 0 4.85 1.93 4.85 4.34a3.93 3.93 0 01-2.27 3.58 4 4 0 012.5 3.87 4.57 4.57 0 01-4.89 4.65c-3.25 0-5.09-1.86-5.23-4.39h2.44a2.38 2.38 0 002.65 2 2.27 2.27 0 002.34-2.37c0-1.62-1-2.6-2.8-2.6h-1.12z"
          />
          <path
            className="prefix__cls-1"
            d="M29 57.9A29 29 0 1157.9 29 29 29 0 0129 57.9zm0-54.65A25.7 25.7 0 1054.65 29 25.73 25.73 0 0029 3.25z"
          />
        </g>
      </g>
    </svg>
  );
}

const MemoE3_logo = React.memo(E3_logo);
export default MemoE3_logo;
